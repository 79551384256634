import { users } from '@prisma/client';
import { EmployeeInCompany } from 'lib/types/company';
import { UserStatus, EmployeeType, AccountType } from 'lib/types/user';

type UserStatusInput = Partial<users & { status: UserStatus }>;
type EmployeeTypeInput = Partial<users & { employee_type: EmployeeType }>;

export function userFullName(user: Partial<users>): string {
  return [user.first_name, user.last_name].join(' ');
}

export function isLineManager(userID: string, employee: EmployeeInCompany) {
  return userID === employee.line_manager_id;
}

export function employeeType(user: EmployeeTypeInput): string {
  switch (user.employee_type) {
    case 'FT':
      return 'Full-time';
    case 'PT':
      return 'Part-time';
    case 'CON':
      return 'Contractor';
    default:
      return '';
  }
}

export function isAdmin(user: Partial<users> | AccountType): boolean {
  return (
    (user as Partial<users>)?.account_type === AccountType.Admin ??
    user === AccountType.Admin
  );
}

// @deprecated
export function isEmployee(
  userOrUserAccountType: Partial<users> | AccountType,
): boolean {
  if (typeof userOrUserAccountType === 'number') {
    return userOrUserAccountType === AccountType.Employee;
  }

  return (
    (userOrUserAccountType as Partial<users>)?.account_type ===
    AccountType.Employee
  );
}

export function isAnyAdmin(user: Partial<users> | AccountType): boolean {
  const availableAdmins = [
    AccountType.PayrollAdmin,
    AccountType.Admin,
    AccountType.SuperAdmin,
  ];
  if (user as Partial<users>) {
    return availableAdmins.includes((user as Partial<users>)?.account_type);
  }

  return availableAdmins.includes(user as AccountType);
}
